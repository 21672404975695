import { last } from "lodash";
import { createContext, useEffect, useState } from "react";
import useWebSocket from "react-use-websocket"

const STORAGE_KEY = 'accessToken';

export const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
    const accessToken = localStorage.getItem(STORAGE_KEY);
    const { sendJsonMessage, lastJsonMessage } = useWebSocket('wss://vicsmart.io/api/v1/ws/ui',{shouldReconnect: () => true});
    const [results, setResults] = useState({});
    const sendMessage = (type, action, data) => {
        sendJsonMessage({
            token: accessToken,
            type,
            action,
            data
        })
    }
    
    const clearResults = () => {
        setResults({})
    }

    useEffect(() => {
        if(lastJsonMessage) {
            setResults(prevState => ({
                ...prevState,
                // [lastJsonMessage.type]: lastJsonMessage.data
                ...lastJsonMessage
            }))
        }
    }, [lastJsonMessage])

    return (
        <SocketContext.Provider
            value={{
                sendMessage,
                results,
                clearResults
            }}
        >
            {children}
        </SocketContext.Provider>
    )
}